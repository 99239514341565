<template>
  <div class="_page-content">
    <a-row :gutter="20">
      <a-col
        :md="5"
        :sm="24"
      >
        <a-alert
          type="info"
          :showIcon="true"
        >
          <div slot="message">
            当前选择：
            <a v-if="currSelected.title">{{ getCurrSelectedTitle() }}</a>
            <a
              v-if="currSelected.title"
              @click="onClearSelected"
              style="margin-left: 10px"
            >取消选择</a>
          </div>
        </a-alert>
        <a-input-search
          @search="onSearch"
          style="width: 100%; margin-top: 10px"
          placeholder="请输入目录名称"
        />
        <!-- 树-->
        <a-dropdown
          :trigger="[this.dropTrigger]"
          @visibleChange="dropStatus"
        >
          <a-tree
            style="user-select: none; height: 50vh; overflow-y: auto"
            multiple
            @select="onSelect"
            @check="onCheck"
            :selectedKeys="selectedKeys"
            :checkedKeys="checkedKeys"
            :treeData="departTree"
            :checkStrictly="true"
            :expandedKeys="iExpandedKeys"
            :autoExpandParent="autoExpandParent"
            @expand="onExpand"
          />
        </a-dropdown>
      </a-col>
      <a-col
        :md="19"
        :sm="24"
        class="search"
      >
        <ax-table
          ref="tableBox"
          :show-search="true"
          :searchActions="searchActions"
          :searchForm="searchForm"
          :columns="columns"
          :dataSourceApi="api.fileList"
          :autoLoad="false"
          :sqlParams="sqlParams"
          :dataSourceParams="dataSourceParams"
          :clickable="false"
          :scroll="{ y: '55vh', x: '80vw' }"
          @action-column-click="actionColumnClick"
          @on-search-click="onSearchClick"
        >
        </ax-table>
      </a-col>
    </a-row>
    <a-modal
      @cancel="fileCancel"
      @ok="fileOk"
      :confirm-loading="confirmLoading"
      :visible="visible1"
      title="附件上传"
    >

      <ax-form
        ref="formBox1"
        :formBuilder="formBuilder"
      >
        <template slot="fileId">
          <a-upload
            ref="upload"
            name="file"
            :multiple="false"
            :file-list="fileList1"
            :customRequest="handleUpload"
            @change="handleChange"
          >
            <a-button size="small">
              <a-icon type="upload" />上传附件
            </a-button>
            <div
              v-if="fileList2.length>0"
              class="preview"
            >
              <a-icon
                class="paper-clip"
                type="paper-clip"
              />
              <span class="file">{{fileList2[0].originalFileName}}</span>
            </div>
          </a-upload>
        </template>
      </ax-form>
    </a-modal>
  </div>
</template>

<script>
import api from './api'
import { initGridFormData, isEmpty } from '@/common/tools'
const YN = [
  {
    label: '私密',
    value: '0'
  },
  {
    label: '公开',
    value: '1'
  }
]
const searchForm = [
  {
    label: '文件名称',
    type: 'input',
    model: 'fileName',
    options: {},
    col: { span: 6 }
  },
  {
    label: '文件类型',
    type: 'select',
    model: 'fileType',
    options: {},
    col: { span: 6 }
  },
  {
    label: '所属部门',
    type: 'treeSelect',
    model: 'fileDept',
    options: {},
    col: { span: 6 }
  }
]
export default {
  name: 'TestAxTable',
  data () {
    return {
      showUploadList: false,
      api,
      id: '',
      uploadStatus: false,
      tableData: [],
      fileType: '',
      fileList1: [],
      fileList2: [],
      fileData: {},
      fileTypeList1: [],
      deptList1: [],
      dirTreeData: [],
      visible1: false,
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: 'horizontal',
        labelWidth: 70
      }),
      // tableUtil,
      pager: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      dataSourceParams: {
        upUserId: this.$store.state.app.userInfo.id
      },
      // 树形部分
      departTree: [],
      treeData: [],
      currSelected: {},
      crChargeDept: '', // 分页查询部门ID
      checkedKeys: [],
      selectedKeys: [],
      autoExpandParent: true,
      iExpandedKeys: [],
      dropTrigger: '',
      visible: false,
      confirmLoading: false,
      queryParams: {},
      initialValues: {},
      columns: this.$ax.tools.initColumn(
        [
          {
            title: '文件名称',
            dataIndex: 'name',
            ellipsis: true,
            width: '20%'
          },
          {
            title: '文件描述',
            dataIndex: 'description',
            ellipsis: true,
            align: 'left',
            width: '30%'
          },
          {
            title: '文件所属部门',
            dataIndex: 'fileDeptName',
            ellipsis: true,
            width: '20%'
          },
          {
            title: '文件类型',
            dataIndex: 'fileType_dictText',
            ellipsis: true,
            width: '20%'
          },
          {
            title: '隐私设置',
            dataIndex: 'seeType_dictText',
            ellipsis: true,
            width: '20%'
          }
        ],
        true,
        {
          width: 150,
          actions: [
            {
              props: (record) => {
                return {
                  text: '编辑',
                  name: 'handleEdit',
                  type: '#3853db',
                  link: true,
                  loading: record.id === 0,
                  disabled: record.upUserId !== this.$store.state.app.userInfo.id
                }
              }
            },
            {
              props: (record) => {
                return {
                  text: '下载',
                  name: 'handleDownload',
                  type: '#3853db',
                  link: true,
                  loading: record.id === 0
                }
              }
            }
          ]
        }
      ),
      searchActions: [
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' },
        { name: 'upload', text: '新增' }
      ],
      formBuilder: initGridFormData(
        [
          {
            label: '文件名称',
            type: 'input',
            model: 'name',
            options: {
              placeholder: '请输入文件名称',
              allowClear: true
            },
            rules: [{ required: true, message: '请输入文件名称' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '文件描述',
            type: 'input',
            model: 'description',
            options: {
              placeholder: '请输入文件描述',
              allowClear: true
            },
            rules: [{ required: true, message: '请输入文件描述' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '所属目录',
            type: 'treeSelect',
            model: 'fileLbraryId',
            options: {
              placeholder: '请选择所属目录',
              allowClear: true,
              disabled: true
            },
            rules: [{ required: true, message: '请选择所属目录' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '所属部门',
            type: 'select',
            model: 'fileDept',
            options: {
              placeholder: '请选择所属部门',
              allowClear: true
            },
            rules: [{ required: true, message: '请选择所属部门' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '隐私设置',
            type: 'select',
            model: 'seeType',
            options: {
              placeholder: '请选择隐私设置',
              allowClear: true,
              options: YN
            },
            rules: [{ required: true, message: '请选择隐私设置' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '文件类型',
            type: 'select',
            model: 'fileType',
            options: {
              placeholder: '请选择文件类型',
              allowClear: true
            },
            rules: [{ required: true, message: '请选择文件类型' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '附件上传',
            type: '',
            model: 'fileId',
            options: {},
            rules: [{ required: true, message: '请上传文件' }],
            formItem: { colon: false },
            col: { span: 24 }
          }
        ],
        {
          layout: 'horizontal',
          labelWidth: 90
        }
      ),
      sqlParams: {
        like: ['name']
      }
    }
  },
  async mounted () {
    this.loadTree()
    this.getList()
    this.fileTypeList()
    this.deptList()
    this.getDirList()
  },
  methods: {
    fileCancel () {
      this.visible1 = false
      this.id = ''
      this.fileData = {}
      this.$nextTick(() => {
        this.$refs.formBox1.resetFields()
        this.fileList1 = []
        this.fileList2 = []
      })
    },
    fileOk () {
      this.$refs.formBox1.form.validateFields(async (errors, values) => {
        if (!errors) {
          if (this.fileData.id === undefined) {
            this.$message.warning('您未上传文件或文件正在上传中,请稍等片刻')
            return
          }
          const report = this.$refs.formBox1.getFieldsValue()
          report.fileId = this.fileData.id
          report.fileDetails = this.fileData
          report.filePath = this.fileData.url
          report.fileSuffix = this.fileData.ext
          if (!this.id) {
            for (let i = 0; i < this.deptList1.length; i++) {
              if (report.fileDept === this.deptList1[i].value) {
                report.fileDeptName = this.deptList1[i].label
              }
            }
          }
          if (!isEmpty(this.id)) {
            report.id = this.id
          } else {
            report.id = ''
          }
          this.confirmLoading = true
          api.updateFile(report).then((res) => {
            if (res.status === 200) {
              this.id = ''
              this.fileData = {}
              this.$refs.formBox1.resetFields()
              this.fileList1 = []
              this.$message.success('提交成功')
              this.visible1 = false
              this.getList()
            } else {
              this.$message.error(res.message)
            }
            this.confirmLoading = false
          })
        }
      })
    },
    handleChange (info) {
      const fileList = [...info.fileList]
      this.fileList2 = []
      this.fileList1 = fileList.slice(-1)
      info.file.status = 'done'
    },
    handleUpload ({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      api.uploadFile(formData).then((res) => {
        if (res.status === 200) {
          this.$message.success('上传成功')
          this.fileData = res.data
          this.uploadStatus = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
    async deptList () {
      await api.deptTreeList({ parentId: 0 }).then((res) => {
        const options = res.data
        this.deptList1 = options
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          'fileDept',
          { options: { treeData: options } }
        )
      })
    },
    getDirList () {
      api.getTree().then((res) => {
        // 遍历成树结构
        const tree = res.data.map(res => {
          return {
            title: res.nodeName,
            key: res.id,
            value: res.id,
            id: res.id,
            parentId: res.parentId,
            children: res.children.map(res => {
              return {
                id: res.id,
                title: res.nodeName,
                key: res.id,
                value: res.id,
                parentId: res.parentId,
                children: []
              }
            })
          }
        })
        this.dirTreeData = [...JSON.parse(JSON.stringify(tree))]
      })
    },
    onSearchClick ({ btn, formValues }) {
      switch (btn.name) {
        case 'upload':
          this.upload()
          break
        case 'search':
          break
        case 'reset':
          break
      }
    },
    upload () {
      if (isEmpty(this.currSelected.key)) {
        this.$message.warning('请选中左侧目录节点')
        return
      }
      this.visible1 = true
      this.$nextTick(() => {
        this.$refs.formBox1.setOptions(
          ['fileType'],
          'options',
          this.fileTypeList1
        )
        this.$refs.formBox1.setOptions(['fileDept'], 'options', this.deptList1)
        if (this.$store.state.app.userInfo.deptId !== '1') {
          this.$refs.formBox1.setFieldsValue({ fileDept: this.$store.state.app.userInfo.deptId })
        }
        this.treeSelect(1)
      })
    },
    // 树形部分代码  当前选择显示
    getCurrSelectedTitle () {
      return !this.currSelected.title ? '' : this.currSelected.title
    },
    onClearSelected () {
      this.checkedKeys = []
      this.currSelected = {}
      this.selectedKeys = []
    },
    getList () {
      this.dataSourceParams.pageNo = this.pager.pageNo
      this.dataSourceParams.pageSize = this.pager.pageSize
      this.$refs.tableBox.getDataSource()
    },
    actionColumnClick (args) {
      const {
        btn: { name },
        record
      } = args
      switch (name) {
        case 'handleEdit':
          this.visible1 = true
          this.$nextTick(() => {
            this.fileData = record
            this.id = record.id
            this.fileList2.push(record.fileDetails)
            this.$refs.formBox1.form.setFieldsValue(record)
            this.$refs.formBox1.setOptions(
              ['fileType'],
              'options',
              this.fileTypeList1
            )
            this.$refs.formBox1.setOptions(['fileDept'], 'options', this.deptList1)
            this.treeSelect(2)
          })

          break
        case 'handleDownload':
          window.open(
            `${process.env.VUE_APP_BASE_URL}/file/download/?id=${record.fileId}`
          )
          break
      }
    },
    onSearch (value) {
      const that = this
      if (value) {
        api.searchByKeywords({ keyWord: value }).then((res) => {
          that.departTree = []
          for (let i = 0; i < res.data.length; i++) {
            const temp = res.data[i]
            that.departTree.push(temp)
          }
        })
      } else {
        that.loadTree()
      }
    },
    loadTree () {
      this.$nextTick(() => {
        api.getTree().then((res) => {
          if (res.data.length > 0) {
            this.departTree = res.data.map(res => {
              return {
                title: res.title,
                key: res.id,
                value: res.id,
                disabled: true,
                dataRef: res,
                children: res.children.map(res => {
                  return {
                    title: res.nodeName,
                    key: res.id,
                    value: res.id,
                    dataRef: res,
                    children: []
                  }
                })
              }
            })
          } else {
            this.$message.warning('您没有该权限！')
          }
          this.loading = false
        })
      })
    },
    onLoadData (treeNode) {
      console.log(treeNode)
      return new Promise((resolve) => {
        if (treeNode.dataRef.children) {
          api.fileClassList({ parentId: treeNode.dataRef.id }).then((res) => {
            if (res.data.length > 0) {
              treeNode.dataRef.children = res.data
            }
            this.loading = false
          })
          resolve()
          return
        }
        setTimeout(() => {
          this.departTree = [...this.departTree]
          resolve()
        }, 1000)
      })
    },

    // 选择左侧树形列表查询方法
    onSelect (selectedKeys, e) {
      const record = e.node.dataRef
      console.log(record)
      this.currSelected = Object.assign({}, record)
      this.selectedKeys = [record.key]
      this.dataSourceParams.fileLbraryId = record.id
      this.dataSourceParams.pageNo = this.pager.pageNo
      this.dataSourceParams.pageSize = this.pager.pageSize
      this.dataSourceParams.fileLbraryId = this.currSelected.key
      this.$refs.tableBox.getDataSource()
      this.setValuesToForm(record)
    },
    // 触发onSelect事件时,为部门树右侧的form表单赋值
    setValuesToForm (record) {
      this.crChargeDept = record.id
      this.pager.pageNo = record.total
    },
    onCheck (checkedKeys, info) {
      this.checkedKeys = checkedKeys.checked
    },
    onExpand (expandedKeys) {
      this.iExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    setThisExpandedKeys (node) {
      if (node.children && node.children.length > 0) {
        this.iExpandedKeys.push(node.key)
      }
    },
    // 右键点击下拉框改变事件
    dropStatus (visible) {
      if (visible === false) {
        this.dropTrigger = ''
      }
    },

    modalFormOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.tableBox.getDataSource()
    },
    handleEdit: function ({ record }) {
      this.visible1 = true
    },

    handleDownload: function ({ record }) {
      window.open(
        `${process.env.VUE_APP_BASE_URL}/file/download/?id=${record.id}`
      )
    },

    async reset () {
      this.$refs.tableBox.setFieldsValue({
        fileName: '',
        fileType: '',
        fileDept: '',
        delFlag: ''
      })
      this.dataSourceParams.fileName = ''
      this.dataSourceParams.fileType = ''
      this.dataSourceParams.fileId = ''
      this.dataSourceParams.delFlag = ''
      this.fileData = {}
      this.id = ''
      this.uploadStatus = false
      this.getList()
    },
    restData () {
      this.$refs.tableBox.setFieldsValue({
        fileName: '',
        fileType: '',
        fileDept: '',
        delFlag: ''
      })
      api.fileList().then((res) => {
        this.tableData = res.data.records
        this.pager.pageNo = res.data.current
        this.pager.pageSize = res.data.size
        this.pager.total = res.data.total
      })
      this.$refs.tableBox.getTableData()
    },
    close () {
      this.$refs.tableBox.getDataSource()
    },
    onValueChange (value, params) { },

    fileTypeList () {
      api.dictData({ dicKind: 'sys_file_type' }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode }
        })
        this.fileTypeList1 = options
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          'fileType',
          { options: { options } }
        )
      })
    },
    treeSelect (value) {
      var tree = JSON.parse(JSON.stringify(this.dirTreeData)).map(res => {
        return {
          label: res.title,
          title: res.title,
          key: res.id,
          value: res.id,
          disabled: true,
          children: res.children.map(res => {
            return {
              label: res.title,
              title: res.title,
              key: res.id,
              value: res.id,
              children: []
            }
          })
        }
      })
      console.log(this.dirTreeData)
      this.$refs.formBox1.setOptions(['fileLbraryId'], 'treeData', tree)
      if (value === 1) {
        this.$refs.formBox1.setFieldsValue({ fileLbraryId: this.currSelected.key })
      }
    }
  }
}
</script>

<style>
.preview {
  display: flex;
  margin-top: 10px;
  margin-left: 2px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.paper-clip {
}
.file {
  display: inline-block;
  width: 100%;
  margin-left: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
